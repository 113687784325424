import { useState, useEffect } from "react";
import "./App.css";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardContent";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { doc, getDoc } from "firebase/firestore";
import BlasterActions from "./BlasterActions";
import BlasterLinks from "./BlasterLinks";
import { storage } from "./useFirebase";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import caliburnIconSmall from "./img/caliburnIconSmall.png";
import { useNavigate } from "react-router-dom";

export default function BlasterCard(props) {
  const [blasterData, setBlasterData] = useState({
    cardImg: caliburnIconSmall,
  });
  let navigate = useNavigate();
  const db = firebase.firestore();
  const date = new Date();
  const increment = firebase.firestore.FieldValue.increment(2);
  const trendingRef = db
    .collection("trending")
    .doc(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );

  const linkClicked = (event, value) => {
    trendingRef.set({ [blasterData.id]: increment }, { merge: true });
  };

  useEffect(() => {
    const getData = async () => {
      var sourceCollection = "blasters";
      if (props.source === "pending") {
        sourceCollection = "pendingBlasters";
      } else {
      }
      const docRef = doc(firebase.firestore(), sourceCollection, props.blaster);
      const docSnap = await getDoc(docRef);

      const resizedRef = storageRef(
        storage,
        `images/${docSnap.data().imageArray[0]}_1440x810`
      );
      getDownloadURL(resizedRef).then((url) => {
        setBlasterData({
          ...docSnap.data(),
          id: props.blaster,
          cardImg: url,
        });
      });
    };
    getData();
  }, [props.blaster, props.source]);

  const blasterURL = "/blaster?blaster=" + blasterData.id;

  return (
    <Card className="blasterCardWrapper">
      <CardActionArea
        href={blasterURL}
        onClick={() => navigate(blasterURL)}
        onMouseDown={(e) => linkClicked(e, "files")}
      >
        <div style={{ height: "183px", overflow: "hidden" }}>
          {blasterData.cardImg === undefined ? (
            <div className="loaderBox">
              <div className="loader"></div>
            </div>
          ) : (
            <CardMedia
              component="img"
              alt="Blaster Icon"
              src={blasterData.cardImg}
              sx={{ height: "183px" }}
              className="blasterCardMedia"
            />
          )}
        </div>

        <CardContent>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              marginBottom: "-5px",
              fontWeight: "bold",
              fontSize: "30px",
              textSizeAdjust: "auto",
            }}
          >
            {blasterData.blasterName}
          </Typography>
          <Typography
            sx={{ marginBottom: "-5px", fontWeight: "bold", fontSize: "14px" }}
          >
            {blasterData.shortDesc}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ marginTop: "15px" }}
          >
            By: {blasterData.creator}
          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ marginTop: "15px" }}
          >
            <DescriptionGenerator blasterData={blasterData} />
          </Typography>
          <div style={{ position: "absolute", right: "12px", bottom: "0px" }}>
            <BlasterLinks hit={blasterData} />
          </div>
          {/* <BlasterLinks hit={hit}></BlasterLinks> */}
        </CardContent>
      </CardActionArea>

      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <BlasterActions blasterData={blasterData} />
      </CardActions>
    </Card>
  );
}

function DescriptionGenerator(props) {
  // Half and Full Lenght Formating
  var halfFull = "";



  if (props.blasterData.ammo) {
    const ammoCount =
    props.blasterData.ammo.half +
    props.blasterData.ammo.full +
    props.blasterData.ammo.rival +
    props.blasterData.ammo.megaXL +
    props.blasterData.ammo.mega +
    props.blasterData.ammo.rockets;


    if (props.blasterData.ammo.half) {
      halfFull += "Half";
    }

    if (props.blasterData.ammo.half && props.blasterData.ammo.full) {
      halfFull += " and ";
    }

    if (props.blasterData.ammo.full) {
      halfFull += "Full";
    }

    halfFull += " Length " + props.blasterData.propulsion;

    if (props.blasterData.ammo.rival) {
      halfFull = "Rival " + props.blasterData.propulsion;
    }

    if (props.blasterData.ammo.mega) {
      halfFull = "Mega " + props.blasterData.propulsion;
    }

    if (props.blasterData.ammo.rockets) {
      halfFull = "Rocket " + props.blasterData.propulsion;
    }

    if (props.blasterData.ammo.megaxl) {
      halfFull = "Mega XL " + props.blasterData.propulsion;
    }

    if (ammoCount >= 3){
      halfFull = " Multi Ammo " + props.blasterData.propulsion;
    }
  }

  var fpsStr = "";
  if (props.blasterData.fpsLow) {
    fpsStr += props.blasterData.fpsLow;
  }
  if (
    props.blasterData.fpsHigh &&
    props.blasterData.fpsHigh !== props.blasterData.fpsLow
  ) {
    fpsStr += "-" + props.blasterData.fpsHigh;
  }
  fpsStr += " ";

  return (
    <>
      {halfFull}
      <br />
      {props.blasterData.rof}
      <br />
      {fpsStr}
      FPS
    </>
  );
}
