import React, { useState, useEffect } from "react";
import "./App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { SearchClient as TypesenseSearchClient } from "typesense";

import Card from "@mui/material/Card";
import Carousel from "react-multi-carousel";

import BlasterCard from "./BlasterCard";
import home1 from "./img/home1.png";
import home2 from "./img/home2.png";
import home3 from "./img/home3.png";
import { Button } from "@mui/material";
import Slider from "react-slick";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default function Home(props) {
  // const searchClient = algoliasearch(
  //   process.env.REACT_APP_ALGOLIA_APP_ID,
  //   process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  // );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1800 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1500 },
      items: 4,
    },
    smalldesktop: {
      breakpoint: { max: 1500, min: 1100 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1100, min: 770 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 770, min: 0 },
      items: 1,
    },
  };

  const [releasedList, setReleasedList] = useState([]);
  const [trendingList, setTrendingList] = useState([]);
  const [topLists, setTopLists] = useState([]);
  const [timeSelect, setTimeSelect] = useState("week");

  // Set up Typesense Searching
  useEffect(() => {
    let client = new TypesenseSearchClient({
      nodes: [
        {
          host: process.env.REACT_APP_TYPESENSE_NODE, // For Typesense Cloud use xxx.a1.typesense.net
          port: "443", // For Typesense Cloud use 443
          protocol: "https", // For Typesense Cloud use https
        },
      ],
      apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_KEY,
      connectionTimeoutSeconds: 2,
    });

    let releasedParameters = {
      q: "*",
      query_by: "blasterName",
      sort_by: "released:desc",
      per_page: 20,
    };

    client
      .collections("blasters")
      .documents()
      .search(releasedParameters)
      .then(({ hits }) => {
        setReleasedList(hits);
        console.log(hits)
      });

    const getData = async () => {
      var date = new Date();
      const dateString =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

      firebase
        .firestore()
        .collection("top")
        .get()
        .then((snapshot) => {
          // Set week, month , quarter and year
          var listTemp = snapshot.docs.map((doc) => doc.data());

          firebase
            .firestore()
            .collection("trending")
            .doc(dateString)
            .get()
            .then((doc) => {
              var blastersWeek = doc.data();

              //If nothing for current date get previous one
              if (
                blastersWeek === undefined ||
                Object.keys(blastersWeek).length === 0
              ) {
                date.setDate(date.getDate() - 1);
                const dateString =
                  date.getFullYear() +
                  "-" +
                  (date.getMonth() + 1) +
                  "-" +
                  date.getDate();
                firebase
                  .firestore()
                  .collection("trending")
                  .doc(dateString)
                  .get()
                  .then((doc) => {
                    console.log(doc.data());
                    blastersWeek = doc.data();

                    var tuples = [];
                    var values = [];

                    for (var blasterKey in blastersWeek)
                      tuples.push([blasterKey, blastersWeek[blasterKey]]);

                    tuples.sort(function (a, b) {
                      a = a[1];
                      b = b[1];

                      return a > b ? -1 : a < b ? 1 : 0;
                    });

                    for (var i = 0; i < tuples.length; i++) {
                      var key = tuples[i][0];
                      var value = tuples[i][1];

                      values.push({ key: key, value: value });
                    }

                    listTemp[4] = { blasters: values, type: "day" };

                    setTopLists(listTemp);
                  });
              }

              var tuples = [];
              var values = [];

              for (var blasterKey in blastersWeek)
                tuples.push([blasterKey, blastersWeek[blasterKey]]);

              tuples.sort(function (a, b) {
                a = a[1];
                b = b[1];

                return a > b ? -1 : a < b ? 1 : 0;
              });

              for (var i = 0; i < tuples.length; i++) {
                var key = tuples[i][0];
                var value = tuples[i][1];

                values.push({ key: key, value: value });
              }

              listTemp = [...listTemp, { blasters: values, type: "day" }];

              setTopLists(listTemp);
            });

          // default to week
          console.log(listTemp);
          setTrendingList(
            listTemp.find((item) => item.type === "week").blasters
          );
        });
    };
    getData();
  }, []);

  const timeChange = (event) => {
    console.log(topLists);
    setTimeSelect(event.target.value);
    setTrendingList(
      topLists.find((item) => item.type === event.target.value).blasters
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    draggable: false,
  };

  return (
    <>
      <section aria-label="Home Images" className="homeImages">
        <Slider {...settings}>
          <div className="homeImageContainer">
            <img
              src={home1}
              alt="Home"
              className="homeImage"
            />
          </div>
          <div className="homeImageContainer">
            <img
              src={home2}
              alt="Home"
              className="homeImage"
            />
          </div>
          <div className="homeImageContainer">
            <img
              src={home3}
              alt="Home"
              className="homeImage"
            />
          </div>
        </Slider>
      </section>

      {/* Hot Right Now */}
      <Card className="profileCardHolder">
        <h1
          style={{ margin: "8px 0px 8px 24px" }}
          className="carouselCardTitle"
        >
          Popular{timeSelect === "day" ? " " : " this "}
          <FormControl variant="standard">
            {/* <InputLabel id="demo-simple-select-label">Propulsion</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={timeSelect}
              // label="Propulsion"
              onChange={timeChange}
              name="propulsion"
              className="carouselCardTitle"
              sx={{
                textAlign: "left",
                fontFamily: "Segoe UI",
                fontWeight: "700",
                marginTop: "-5px",
              }}
            >
              <MenuItem value={"day"}>Today</MenuItem>
              <MenuItem value={"week"}>Week</MenuItem>
              <MenuItem value={"month"}>Month</MenuItem>
              <MenuItem value={"quarter"}>Quarter</MenuItem>
              <MenuItem value={"year"}>Year</MenuItem>
            </Select>
          </FormControl>
        </h1>
        <Carousel
          responsive={responsive}
          showDots={true}
        >
          {trendingList.map((obj) => (
            <BlasterCard blaster={obj.key} key={obj.key} />
          ))}
        </Carousel>
      </Card>

      {/* Trending */}
      <Card className="profileCardHolder">
        <h1 style={{ margin: "8px 0px 8px 24px" }}>New Releases: </h1>

        <Carousel responsive={responsive} showDots={true}>
          {releasedList.map((obj) => (
            <BlasterCard blaster={obj.document.id} key={obj.document.id} />
          ))}
        </Carousel>
      </Card>

      {/* Search Page Call to Action */}
      <Card className="searchCTA">
        <Card className="searchCTAText">
          <h2>Still didn't find what you were looking for?</h2>
          <h3>
            Head over to the search page to view the full database of blasters:
          </h3>

          <Button
            size="large"
            variant="contained"
            href="/search"
            className="searchCTAButton"
          >
            Search Page
          </Button>
        </Card>
      </Card>
    </>
  );
}
