import "./App.css";
import { useState } from "react";
import firebase from "firebase/compat/app";
import { updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function BlasterActions(props) {
  const db = firebase.firestore();
  const date = new Date();
  const increment = firebase.firestore.FieldValue.increment(3);
  const decrement = firebase.firestore.FieldValue.increment(-2);
  const trendingRef = db
    .collection("trending")
    .doc(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );

  if (props.blasterData.objectID) {
    props.blasterData.id = props.blasterData.objectID;
  }

  var userid = "";
  const [wishClicked, setWishClicked] = useState(false);
  const [collClicked, setCollClicked] = useState(false);
  const [role, setRole] = useState("User");
  const [user, setUser] = useState("");

  var unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((value) => {
          setWishClicked(
            value.data()["wishlist"].includes(props.blasterData.id)
          );
          setCollClicked(
            value.data()["collected"].includes(props.blasterData.id)
          );
          setRole(value.data()["role"]);
          setUser(user.uid);
        });
      userid = user.uid;
    } else {
    }
  });

  unsubscribe();

  const wishlistAdd = (e) => {
    if (user === "") {
      alert(
        "Please log in or create an account to add a blaster to your collection."
      );

      return;
    }

    var docWish = db.collection("users").doc(userid);
    if (!wishClicked) {
      updateDoc(docWish, {
        wishlist: arrayUnion(props.blasterData.id),
      });
      setWishClicked(true);

      trendingRef.set({ [props.blasterData.id]: increment }, { merge: true });
    } else {
      docWish.update({
        wishlist: arrayRemove(props.blasterData.id),
      });
      setWishClicked(false);

      trendingRef.set({ [props.blasterData.id]: decrement }, { merge: true });
    }
  };

  const collectedAdd = (e) => {
    if (user === "") {
      alert(
        "Please log in or create an account to add a blaster to your collection."
      );

      return;
    }

    var docColl = db.collection("users").doc(userid);
    if (!collClicked) {
      updateDoc(docColl, {
        collected: arrayUnion(props.blasterData.id),
      });
      setCollClicked(true);

      trendingRef.set({ [props.blasterData.id]: increment }, { merge: true });
    } else {
      docColl.update({
        collected: arrayRemove(props.blasterData.id),
      });
      setCollClicked(false);

      trendingRef.set({ [props.blasterData.id]: decrement }, { merge: true });
    }
  };

  return (
    <div className="blasterActions">
      {["Admin", "Moderator"].includes(role) ? (
        <Button
          // sx={{maxHeight: "30px"}}
          color="warning"
          size="small"
          variant="contained"
          href={"./update?blaster=" + props.blasterData.id}
        >
          Update
        </Button>
      ) : (
        <Button
          // sx={{maxHeight: "30px"}}
          color="warning"
          size="small"
          variant="contained"
          href={"./requestupdate?blaster=" + props.blasterData.id}
        >
          Update
        </Button>
      )}

      <Button
        color={wishClicked ? "success" : "primary"}
        size="small"
        variant="contained"
        startIcon={wishClicked ? <CheckCircleIcon /> : <AddCircleIcon />}
        onClick={wishlistAdd}
        id="wButton"
      >
        Wishlist
      </Button>

      <Button
        color={collClicked ? "success" : "primary"}
        variant="contained"
        size="small"
        startIcon={collClicked ? <CheckCircleIcon /> : <AddCircleIcon />}
        onClick={collectedAdd}
      >
        Collect
      </Button>
    </div>
  );
}
