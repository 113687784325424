import React, { useState } from "react";
import "./App.css";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import Header from "./Header.js";
import Footer from "./Footer.js";
import BlasterPage from "./BlasterPage.js";
import Home from "./Home.js";
import Search from "./Search.js";
import AddBlaster from "./AddBlasterPage/AddBlaster.js";
import UpdateBlaster from "./AddBlasterPage/UpdateBlaster.js";
import UpdateCompare from "./AddBlasterPage/UpdateCompare.js";
import Profile from "./Profile.js";
import FeatureRequest from "./FeatureRequest.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  const [user, setUser] = useState([]);
  const [role, setRole] = useState([]);

  function gotUser(userVal) {
    setUser(userVal);
  }
  function gotRole(roleVal) {
    setRole(roleVal);
  }


  return (
    <Router>
      <Header onChange={gotUser} passRole={gotRole}/>
      <Routes>
        <Route path="/" element={<Home user={user} />} />
        <Route path="/search" element={<Search user={user} pending = {false} />} />
        <Route path="/searchPending" element={<Search pending = {true} user={user} />} />
        <Route path="/blaster" element={<BlasterPage />} />
        <Route path="/add" element={<AddBlaster />} />
        <Route path="/update" element={<UpdateBlaster role={role}/>} />
        <Route path="/pendingBlaster" element={<UpdateBlaster pending = {true} role={role}/>} />
        <Route path="/userupdate" element={<UpdateCompare role={role}/>} />
        <Route path="/requestupdate" element={<UpdateBlaster request={true} />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/features" element={<FeatureRequest />} />
        {/* <Route path='/blogs' element={<Blogs/>} />
          <Route path='/sign-up' element={<SignUp/>} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
